<template>
    <div>
      <b-row class="p-0 m-0 pl-1">
        <b-col sm="12" class="p-0">
          <iq-card>
            <div class="iq-card-body profile-page p-0 profilePages listPage">
              <div class="profile-header profile-info">
                <div class="cover-container">
                  <img :src="vmBannerImage" class="rounded img-fluid">
                </div>
                <div class="user-detail text-center mb-3">
                  <div class="profile-img">
                    <b-avatar variant="primary" class="avatar-100 rounded">
                      <i class="fa fa-university" aria-hidden="true" style="font-size: 35px;"></i>
                    </b-avatar>
                  </div>
                  <div class="profile-detail">
                    <h3 v-if="Object.values(participatedUniversityListObj).length >0">
                      {{eventName}}
                    </h3>
                    <h3 v-else>
                      No events
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </iq-card>
        </b-col>
        <b-col sm="12" class="p-0">
          <div class="tab-content p-0">
            <tab-content-item :active="true" id="profile-all-universities" aria-labelled-by="pills-all-university-tab">
              <div v-if="Object.values(participatedUniversityListObj).length >0">
                <b-row class="p-2">
                  <div v-for="(org,index) in participatedUniversityListObj" :key="index + Object.keys(participatedUniversityListObj).length" class="mt-5 col-sm-4">
                    <iq-card className="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-badges text-left" style="cursor: pointer;">
                          <div class="badges-icon">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org.image">
                              <img :src="org.image" class="avatar-80 rounded" alt="Organisation">
                            </b-avatar>
                            <b-avatar v-else class="avatar-80 rounded" :text="getFirstLetterOfAString(org.org_name)" style="border-radius: 100%!important;">
                            </b-avatar>
                          </div>
                          <h5 class="mb-2 title homeListShowHeading3Line">
                            {{org.org_name}}
                          </h5>
                          <p class="threeLineOnly vhtmlTag vueHtmlParent" v-html="org.org_desc">
                          </p>
                          <div class="d-flex justify-content-between">
                            <span class="text-uppercase">{{org.org_city}} {{org.org_city ? ', '+org.org_country : org.org_country}}</span>
                            <button style="all:unset; font-weight: 500; cursor: pointer;" @click="checkIn(org.org_id, index)">
                              <span class="text-primary">
                                {{participatedUniversityListObj[index].checkIn ? 'Checked' : 'Check In'}}
                              </span>
                            </button>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </div>
                </b-row>
              </div>
              <div v-else class="p-2 text-center flex flex-fill justify-content-center">
                {{isLoading ? 'Loading ....' : 'No Organisation Found'}}
              </div>
            </tab-content-item>
          </div>
        </b-col>
      </b-row>
      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </div>
</template>
<style lang="scss" scoped>
  .avatar-80 {
    object-fit: contain;
    background: white;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events.js"
import moment from "moment"
import Utility from "../../../Utils/utility.js"
import UserRoles from "../../../FackApi/json/UserRoles.json"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "participatedUniversityList",
  data () {
    return {
      participatedUniversityListObj: {},
      allUnivcurrentPage: 1,
      showLoadMoreBtn: false,
      showLoadFollowingBtn: false,
      vmUnivSearch: "",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Check In Response",
      vmBannerImage: Utility.getBgImageForLetter(this, "UNIV"),
      isLoading: false,
      eventName: "",
      cvLoadingStatus: false
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
  },
  methods: {
    /**
      * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.participatedUniversityList()
      // this.myUniversityList()
    },
    /**
     * getFormattedDate
    */
    getFormattedDate (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
       * getFirstLetterOfAString
       */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
       * getResizedText
       */
    getResizedText (text) {
      return text ? text.substr(0, 100) : ""
    },
    /*
     * participatedUniversityList
     * */
    async participatedUniversityList () {
      this.isLoading = true
      try {
        let organisationListResp = await events.eventParticipateUniversity(this)
        if (organisationListResp && organisationListResp.resp_status) {
          this.participatedUniversityListObj = organisationListResp.resp_data.data
          this.eventName = this.participatedUniversityListObj[0].event_name
        }
      }
      catch (err) {
        console.error("Exception occurred at participatedUniversityList() and Exception:", err.message)
      }
      finally {
        this.isLoading = false
      }
    },
    /**
     * checkIn
     */
    async checkIn (orgId, index) {
      try {
        if (this.participatedUniversityListObj[index].checkIn) {
          return
        }

        this.cvLoadingStatus = true
        let payload = {
          user_id: this.userData.user_id,
          user_name: this.userData.user_name,
          user_email: this.userData.user_email,
          user_mobile: this.userData.user_mobile,
          event_id: this.participatedUniversityListObj[0].event_id,
          event_hosting_platform: this.participatedUniversityListObj[0].event_hosting_platform,
          module_name: UserRoles[this.userData.user_role],
          module_obj_id: orgId, // Alway set it in the module_id so that we can re-use it for different modules
          duration: this.participatedUniversityListObj[0].event_duration
        }
        let submittedDataResp = await events.eventUserBoothCheckin(this, payload)
        ApiResponse.responseMessageDisplay(this, submittedDataResp)
        if (submittedDataResp.resp_status) {
          this.participatedUniversityListObj[index].checkIn = true
        }
      }
      catch (err) {
        console.error("Exception occurred at checkIn() and Exception:", err)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
